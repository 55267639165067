import React, { useState } from 'react';
import { PriceDelivery } from '../../server/Polygon';
import { ShippingAddressPost } from '../../server/ShippingAddress'
import { useSelector, useDispatch } from 'react-redux'
import showAlert from '../../server/ShowAlert';
import Autocomplete from 'react-google-autocomplete';
import { setPreload } from '../../server/CartsOpen';
import Input from '../containers/InputForm';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import * as actionType from '../../store/action'

const schema = yup.object({
    Name: yup.string().required("שם חובה"),
    StreetNumber: yup.string().required("חובה להכניס מס' בניין"),
    HouseNumber: yup.string().required("חובה להכניס מס' בית ").test('len', "לא תקין", x => !isNaN(x) || x.length <= 3),
    Floor: yup.string().required('חובה להכניס קומה').test('len', "לא תקין", x => x.length <= 12),
    Entry: yup.string().test('len', "לא תקין", x => x.length <= 20),
    AddressComment: yup.string(),
}).required();


const NewAddress = ({ pushAddress, address, polygonState, onlyStreet, closeSignUpPopup }) => {
    const dispatch = useDispatch();

    const { user } = useSelector(state => ({
        user: state.user.user

    }));
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const [town, setTown] = useState("")
    const [streetName, setStreetName] = useState("")
    const [lng, setLng] = useState("")
    const [lat, setLat] = useState("");
    const [checkBox, setCheckBox] = useState(true);
    const [elevators, setElevators] = useState("noElevators");
    const [always, setAlways] = useState(true);

    const saveAddress = (data) => {
        dispatch(setPreload(true));
        data.CustomerId = user.Id;
        data.lat = lat;
        data.lng = lng;
        data.town = town;
        data.streetName = streetName;
        data.MainAddress = checkBox ? 1 : 0;
        data.IsElevator = elevators === "noElevators" ? false : true;
        ShippingAddressPost(data)
            .then(x => {
                x.data["MainAddress"] = checkBox ? 1 : 0
                pushAddress(x.data, address);
                dispatch({ type: actionType.VERIFIED_EMAIL })
            })
            .catch(() => console.log("error"))
            .finally(() => dispatch(setPreload(false)));

    }

    const setLocation = (place) => {      
        let polygon;
        if (!place.geometry) {
            showAlert({ msgTitle: 'אנא הזינו את שם הרחוב בלבד ובחרו מהשדות המוצעות לכם', msgTimer: '3000', msgType: 'info' })
            return;
        }
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng()
        polygon = polygonState;
        if (PriceDelivery(lat, lng, polygon)) {
            if (place.address_components.length === 4) {
                setStreetName(place.address_components[0].long_name)
                setTown(place.address_components[1].long_name)
            }
            else if (place.address_components.length <= 6 && place.address_components.length >= 5) {
                if (place.address_components[0].types[0] === "street_number") {
                    setValue('StreetNumber', place.address_components[0].long_name)
                    setStreetName(place.address_components[1].long_name)
                    setTown(place.address_components[2].long_name)
                }
                else if (place.address_components[0].types[0] === "route") {
                    setStreetName(place.address_components[0].long_name)
                    setTown(place.address_components[1].long_name)
                }
            }
            else if (place.address_components.length < 4) {
                showAlert({ msgTitle: 'אנא הזינו את שם הרחוב ועיר המגורים', msgType: 'error' })
                return;
            }
            setLat(place.geometry.location.lat());
            setLng(place.geometry.location.lng());
            showAlert({ msgTitle: 'מצויין, אנו מגיעים אליכם.', msgType: 'success' })
            closeSignUpPopup();
        } else {
            showAlert({ msgTitle: 'אופס, איננו מבצעים משלוחים לאיזור זה. עמכם הסליחה.', msgType: 'error' })
        }
    }
    return (
        <div className="add-address">
            {onlyStreet ? (
                <></>
            ) : (
                <span className='note'>שדות חובה מסומנים ב*</span>
            )} 
            <form className="register" onSubmit={handleSubmit(saveAddress)}>
            {always ? (
                <span className="autocomplete-address">
                {onlyStreet ? (
                    <></>
                ) : (
                    <p className="autocomplete-address-title">רחוב*</p>
                )}
                    <Autocomplete
                        className="autocomplete-address-input"
                        options={{
                            componentRestrictions: { country: 'ISR' },
                            types: ['geocode'],
                        }
                        }
                        onPlaceSelected={(e) => setLocation(e)}
                        placeholder='הזינו את שם הרחוב ובחרו מתוך האפשרויות המוצעות לכם'
                    />

                </span>
            ) : null}
            {onlyStreet ? (
                <></>
            ) : (
                <>
                <span className='details-address'>
                    <span className='name'>
                        <Input
                            type="text"
                            register={register}
                            label="שם הכתובת*"
                            errors={errors}
                            name="Name" />
                    </span>
                    <span className='sub-details'>
                        <Input
                            type="number"
                            register={register}
                            label="מס' בית*"
                            errors={errors}
                            name="StreetNumber" />
                        <Input
                            type="text"
                            register={register}
                            label="כניסה"
                            errors={errors}
                            name="Entry" />
                        <Input
                            type="number"
                            register={register}
                            label="קומה*"
                            errors={errors}
                            name="Floor" />
                        <Input
                            type="number"
                            register={register}
                            label="מס' דירה*"
                            errors={errors}
                            name="HouseNumber" />
                    </span>
                </span>
                <span className='note-shipping'>
                    <Input
                        type="text"
                        register={register}
                        label="הערות למשלוחן"
                        errors={errors}
                        name="AddressComment" />

                    <span className='elevators-radio-btn'>
                        <span className='elevators'>
                            <input type="radio" onChange={(e) => setElevators(e.target.value)} id="yesElevators" value="yesElevators" name="elevators" checked={elevators === "yesElevators"} />
                            <label className="yesElevators">יש מעלית</label>
                        </span>
                        <span className='elevators'>
                            <input type="radio" onChange={(e) => setElevators(e.target.value)} id="noElevators" value="noElevators" name="elevators" checked={elevators === "noElevators"} />
                            <label className="noElevators"> אין מעלית</label>
                        </span>
                    </span>
                </span>
                <span className='checkbox-and-btn'>
                    <div className='checkbox-style'>
                        <input
                            type="checkBox"
                            checked={checkBox}
                            onChange={() => setCheckBox(!checkBox)} />
                        <label>הגדר ככתובת ברירת מחדל</label>
                    </div>
                    <button className='btn' type="submit" >{"שמור > "}</button>
                </span>
                </>
            )}
            </form>
        </div>
    )
}

export default NewAddress;