import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { globalFileIcon } from '../../../axiosInstance';
import { useDispatch, useSelector  } from 'react-redux';
import { setToggleCart } from "../../server/CartsOpen";
import { showAlertWithConfirm } from "../../server/ShowAlert";

const HeaderCart = ({ role, user,  productsInCart }) => {
    const dispatch = useDispatch();
    const { orderSum, totalSum, deliveryPrice } = useSelector(state => ({
        orderSum: state.productsInCart.orderSum,
        totalSum: state.productsInCart.totalSum,
        deliveryPrice: state.productsInCart.deliveryPrice,

    }) );
    const [cartIsOpen, setCartIsOpen] = useState(true)
    const setCart = (setOpen) => {
        dispatch(setToggleCart(setOpen))
        setCartIsOpen(!cartIsOpen)
    }
    const goToCart = () => {
        dispatch(setToggleCart(false))
        setCartIsOpen(true)
        const availableItems = productsInCart.filter(product => product.Quantity == 0); 
        if (availableItems.length > 0) {
            const itemNames = availableItems.map(item => item.ProductName).join('<br>');
            showAlertWithConfirm({
                msgHtml: `<div class="missing-items-alert">שים לב אלו הפריטים שאזלו מן המלאי: <div class="missing-items-container">
                    <strong>${itemNames}</strong></div></div>`,
            });
        }
    }
    return (
        <>
            {!role && user ?
                <div className="cart-in-header">
                    <button className="open-cart-btn" onClick={() => setCart(cartIsOpen)}>
                        {cartIsOpen ?
                            <img src={globalFileIcon + 'open-cart.svg'} alt="open-cart" /> :
                            <img className="close-cart" src={globalFileIcon + 'open-cart.svg'} alt="close-cart" />
                        }

                    </button>
                    <div className="sum-in-cart">
                        <div className="sum-and-num-details">
                            <div className="num-product">
                                <img src={globalFileIcon + 'num-details-in-cart.svg'} alt="num-details-in-cart" />
                                {productsInCart.length ?
                                    <span className="num-product-text">{productsInCart.length}</span> : null}

                            </div>
                            {productsInCart.length ?
                                <span className="btn-price"><span>₪</span>{(parseFloat(totalSum) + parseFloat(deliveryPrice) + parseFloat(orderSum)).toFixed(2)}</span>
                                : null}
                        </div>
                        <NavLink onClick={() => goToCart()} className="to-pay-btn" to="/cart">
                            לתשלום
                        </NavLink>
                    </div>
                </div> : null}
        </>
    )
}

export default HeaderCart
