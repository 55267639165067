import React, { Fragment, useEffect, useState, useRef } from "react";
import showAlert from "../../../server/ShowAlert";
import { BaseUrl } from "../../../../axiosInstance";
import { useSelector } from "react-redux";
import axios from "axios";
import { SavePaymentNedarim } from "../../../server/Payment";

const PayNedarim = ({ save, total, ClosePayPopup, numPayments, numPaymentSelect, orderIdInNedarim, pay, handleDebitTokenPayment}) => {
  const user = useSelector((state) => state.user.user);
  const iframeRef = useRef(null);
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);
  const [isRefundInProgress, setIsRefundInProgress] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [token, setToken] = useState(null); 
  const creditDetails = useRef(null);
  const tokef = useRef(null);
  const CVV = useRef(null);

  useEffect(() => {
    const receivingMessages = async (e) => {
      if (e.origin === "https://www.matara.pro") {
        if (e.data && typeof e.data.Value.Status === "string") {
          switch (e.data.Value.Status) {
            case "OK":
              if (e.data.Value.Token) {
                const newToken = await setTokenAsync(e.data.Value.Token);
                handleDebitTokenPayment(newToken, total, tokef.current);
              }
              break;
            case "Error":
              showAlert({
                msgTitle: "העסקה נכשלה",
                msgText: `שגיאה: ${e.data.Value.Message}`,
                msgType: "error",
              });
              ClosePayPopup(false);
              break;
            default:
              console.warn("Unexpected message received:", e.data);
          }
          setIsPaymentInProgress(false);
        }
      }
    };

    window.addEventListener("message", receivingMessages);
    return () => {
      window.removeEventListener("message", receivingMessages);
    };
  }, []); 

  const setTokenAsync = (value) => {
    return new Promise((resolve) => {
      setToken(value);
      resolve(value);
    });
  };

  const PostNedarim = (Data) => {
    if (iframeRef.current) {
     iframeRef.current.contentWindow.postMessage(Data, "*");
    } else {
      showAlert({ msgTitle: 'Error', msgText: 'Could not load payment iframe.', msgType: 'error' });
      return;
    }
  };

  const handlePayment = async () => {
    setIsPaymentInProgress(true);

    const paymentData = {
      Name: "FinishTransaction2",
      Value: {
        Mosad: "7012899",
        ApiValid: "ZZuvcmmoB+",
        Zeout: user.Id,
        FirstName: user.Name,
        LastName: user.LastName,
        City: user.City,
        Phone: user.Phone,
        Mail: user.Email,
        PaymentType: "CreateToken", 
        Amount: total,
        Tashlumim: numPaymentSelect,
        Currency: "1",
        Groupe: "",
        Comment: "",
        Param1: "",
        CallBack: `${BaseUrl}`,
      },
    };
    PostNedarim(paymentData);
  };

  const handleRefund = async (creditAmount) => {
    setIsRefundInProgress(true);
    const roundedAmount = parseFloat(creditAmount.toFixed(2));
    const refundData = {
      Action: "RefundTransaction",
      MosadId: "7012899",
      ApiPassword: "ur829",
      TransactionId: transactionId,
      RefundAmount: roundedAmount
    };
    try {
      const queryString = new URLSearchParams(refundData).toString();

      const url = `https://matara.pro/nedarimplus/Reports/Manage3.aspx?${queryString}`;
      const response = await axios.get(url);

      if (response.data.Result === "OK") {
        showAlert({
          msgTitle: "הזיכוי הושלם בהצלחה",
          msgType: "success",
        });
      } else {       
        showAlert({
          msgTitle: "שגיאה בזיכוי",
          msgText: response.data.Message || "לא נמסרה שגיאה",
          msgType: "error",
        });
      }
    } catch (error) {
      showAlert({
        msgTitle: "שגיאה בשליחת הבקשה",
        msgText: error.message || "אירעה שגיאה בעת שליחת הבקשה",
        msgType: "error",
      });
    } finally {
      setIsRefundInProgress(false);
    }
  };

  const handleInputChange = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, "");
    if (value.length > 2) {
      value = value.slice(0, 2) + "/" + value.slice(2);
    }
    tokef.current = value;
    e.target.value = value;
  };


  return (
    <Fragment>
      <iframe
        id="NedarimFrame"
        ref={iframeRef}
        title="pay"
        src={`https://www.matara.pro/nedarimplus/iframe?Tokef=Hide&CVV=Hide&Picture=Hide`}
        style={{ width: "", height: "", border: "none" }}
      >
        <p>Your browser does not support iframes.</p>
      </iframe>
      <div className="divPayStyle">
        <label htmlFor="tokef" className="txtStyle">תוקף כרטיס האשראי:</label><br></br>
        <input
          className="textBox"
          type="text"
          id="tokef"
          value={tokef.current}
          onChange={handleInputChange}
          placeholder="MM/YY"
          maxLength="5"
          required
        />
        <br></br><br></br>
        <label htmlFor="cvv" className="txtStyle">3 ספרות בגב הכרטיס:</label><br></br>
        <input
          className="textBox"
          type="text"
          id="cvv"
          value={CVV.current}
          onChange={(e) => {CVV.current = e.target.value}}
          placeholder="CVV"
          maxLength="3"
          required
        />
      </div>
      <button
        className={`paymentBtn ${isPaymentInProgress ? "disabled" : ""}`}
        onClick={handlePayment}
        disabled={isPaymentInProgress}
      >
        {isPaymentInProgress ? "ממתין..." : `תשלום`}
      </button>
    </Fragment>
  );
};

export default PayNedarim;