import axios from 'axios';
import { url } from '../../axiosInstance';
import * as actionType from '../store/action';
import showAlert from '../server/ShowAlert';

export const AddNewSalesPost = ( data) => {
    return axios.post(`${url}sale`,
        data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const UpdateStationToSalePost = ( data) => {
    return axios.post(`${url}sale/addStation`,
        data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const UpdateProductToSalePost = ( data) => {
    return axios.post(`${url}sale/addProduct`,
        data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const DeleteSale = ( saleId ) => {
    return axios.post(`${url}sale/deleteList/${saleId}`,{}, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        }
    })
}

export const GetSalesList = () => {
    return axios.get(`${url}sale`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const GetProductList = (saleId) => {
    return axios.get(`${url}sale/Product?SaleId=${saleId}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const GetStationList = (saleId) => {
    return axios.get(`${url}sale/Station?SaleId=${saleId}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}