import React, { Fragment, useEffect } from 'react';
import { GetProducts, getByMkt } from '../../../server/Product'
import { getFilters } from '../../../server/Filter';
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const ProductEdit = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { filterList, product, role, products } = useSelector(state => ({
		filterList: state.displayProduct.filterList,
		product: state.selectedProduct.product,
		products: state.selectedProduct.products,
		role: state.user.role,

	}));

	useEffect(() => {
		if (role) {
			dispatch(getByMkt(id))
			if (!products?.length) {
				dispatch(GetProducts());
			}
			if (filterList) {
				dispatch(getFilters())
			}
			setTimeout(() => window.scrollTo(0, 0), 200);
		}
	}, [role, id])

	return product ?
		<Fragment>
			{/* <h1 className='title-role'> פרטי מוצר</h1> */}
			<div className='tab-header'>
				<div className="tab" 
					 onClick={() => {
						if (product.Category.length === 3) {
							navigate(`/category/${product.Category[0].Id}/${product.Category[1].Id}/${product.Category[2].Id}`);
						} else if (product.Category.length === 2) {
							navigate(`/category/${product.Category[0].Id}/${product.Category[1].Id}`);
						} else if (product.Category.length === 1) {
							navigate(`/category/${product.Category[0].Id}`);
						} else {
							console.warn('No categories available for this product.');
						}
					}}
				> ↪ {product.ProductName}
				</div>              
				<NavLink to="information" className="tab">פרטי מוצר</NavLink>
				<NavLink to="logistic" className="tab">לוגיסטיקה </NavLink>
				<NavLink to="img" className="tab">תמונות</NavLink>
				<NavLink to="comper" className="tab">השוואת מחירים</NavLink>
				<NavLink to="inventory" className="tab">מלאי </NavLink>
				<NavLink to="inventory-update" className="tab">העברת מלאי </NavLink>
			</div>
			<div className="outlet">
				<Outlet />
			</div>

		</Fragment>
		: null

							/* למה לא קיים?
					<NavLink to="logistic" className="tab">לוגיסטיקה </NavLink> 
					<NavLink to="inventory-update" className="tab">העברת מלאי </NavLink>
					*/

}


export default ProductEdit;