import React, { useEffect, useState } from 'react'
import { setUserEntry } from '../../server/Customer'
import { useDispatch } from 'react-redux';
import UserSighIn from './UserSighin';
import UserLogin from './UserLogin';
import PopUp from '../containers/PopUp';
import NewAddress from '../addressShipping/NewAddress';
import { allPolygon } from '../../server/Polygon'

const UserEntry = ({typeOfUser}) => {
	const dispatch = useDispatch();
	 const [isSignUpPopupOpen, setSignUpPopupOpen] = useState(false);
	 const [polygonState, setPolygonState] = useState([]);
	 const [loading, setLoading] = useState(true); 

	 const closeSignUpPopup = () => setSignUpPopupOpen(false);

	 useEffect(() => {
		const fetchData = async () => {
			try {
			  const response = await allPolygon();
			  const polygons = response.data.map(item => item.polygon).flat();
			  setPolygonState(polygons);
			  setLoading(false);
			} catch (error) {
			  setLoading(false); 
			}
		  };
	  
		  fetchData(); 
    }, [])

	if (loading) {
		return <div>טעינת נתונים...</div>;
	}

	return (
		<>
		<PopUp width='700px' close={() => dispatch(setUserEntry(false))}>
			<div className="user-entry">
				<div className='user-entry-header'>
				<h3>כמה פרטים קצרים ויוצאים לדרך</h3>
				<div className='tab-header'>
					<button onClick={() => dispatch(setUserEntry([true, "login"]))} className={typeOfUser === "login" ?'tab login':'tab'}>כניסה לחשבון</button>
					<button onClick={() => {
						dispatch(setUserEntry([true, "signIn"]));
						setSignUpPopupOpen(true); 
					}}
					className={typeOfUser === "signIn" ?'tab signIn':'tab'}>יצירת חשבון חדש</button>
				</div>
				</div>
				{typeOfUser === "login" ?
					<UserLogin  />
					: <UserSighIn parentName='signIn' />}

			</div>
		</PopUp>
            {isSignUpPopupOpen && (
                <PopUp width='520px' close={closeSignUpPopup}>
                    <div className="new-signup-popup">
                        <h2>ברוך הבא ליצירת חשבון חדש!</h2>
                    	<h3>רגע לפני שממשיכים,	בא נבדוק שאנו באמת מגיעים אליכם...</h3>
						<NewAddress polygonState={polygonState} onlyStreet={true} closeSignUpPopup={closeSignUpPopup}></NewAddress>
                    </div>
        		</PopUp>
        )}
        </>

	)
}

export default UserEntry