import PopUp from "../../containers/PopUp";
import { NavLink } from "react-router-dom";

const PopAddress = ({ setShowStationPopUp, shippingAddress }) => {

    return(
        <PopUp close={() => setShowStationPopUp(false)} width='600px'>
            <div className="popAddress">
                <div className="titleAddress">הכתובת המעודכנת במערכת</div>
                {shippingAddress ?
                    <div className="userAddress">{shippingAddress != "כתובת לא נמצאה" ? `${shippingAddress.StreetName} ${shippingAddress.StreetNumber}, ${shippingAddress.Town}` : 'כתובת לא זמינה'}</div>
                : 'כתובת לא זמינה'}
                
                <br></br>
                <button onClick={() => setShowStationPopUp(false)}>אישור</button>
                <br></br>
                <br></br>
                <NavLink to="/private-area/address">
                    <button onClick={() => setShowStationPopUp(false)}>עדכון כתובת</button>
                </NavLink>
            </div>
        </PopUp>
    )
}

export default PopAddress;