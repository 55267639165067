import React, { Fragment, useEffect, useState } from 'react';
import MyCropper from "../../tools/MyCropper";
import { globalFileServer } from '../../../../axiosInstance';
import { GetImgUrlProduct, SetImgActive, getItemsPerCategoryCount } from '../../../server/Product'
import showAlert from '../../../server/ShowAlert';
import { setPreload } from '../../../server/CartsOpen';
import { useDispatch, useSelector } from 'react-redux';
import Detailes from '../card/cardDeailes';
import MyCropperComp from '../../tools/MyCrooperComp';
import { UploadImageData, deleteImageFromServer } from '../../../server/UploadImage';
import {showAlertWithConfirmCancel} from '../../../server/ShowAlert'
// import ViewThreeSixty from '../../containers/ViewThreeSixty';


const ProductEditImg = () => {

    const { product, stationId } = useSelector(state => ({
        product: state.selectedProduct.product,
        stationId: state.user.stationId
    }));


    const [replace, setReplace] = useState(false);
    const [imagesList, setImagesList] = useState([]);
    const [showInList, setShowInList] = useState([])
    const dispatch = useDispatch();
    useEffect(() => {
        getImgUrl(); // רענון הנתונים מהשרת
    }, [product, imagesList]);
    
    const getImgUrl = () => {
        if (product) {
            GetImgUrlProduct(product.Mkt)
                .then(x => {
                    if (x.data) {
                        setImagesList(x.data)
                    }
                    else {
                        showAlert({ msgTitle: "אירעה שגיאה בטעינת תמונות מהמערכת ", msgType: "error" })
                    }
                })
                .catch(() => showAlert());
        }
    }
    const deleteImage = (imageData) => {
        showAlertWithConfirmCancel({
            msgTitle: "האם אתה בטוח שברצונך למחוק את התמונה?",
            confirmText: "מחק",
            cancelText: "ביטול",
            onConfirm: () => {
                dispatch(setPreload(true)); 
                deleteImageFromServer(imageData)
                    .then(() => {
                        const updatedImages = imagesList.filter(img => img.FileName !== imageData.FileName);
                        setImagesList(updatedImages);
                        showAlert({ msgTitle: "התמונה נמחקה בהצלחה", msgType: "success" });
                    })
                    .catch(() => {
                        showAlert({ msgTitle: "שגיאה במחיקת התמונה", msgType: "error" });
                    })
                    .finally(() => {
                        dispatch(setPreload(false)); 
                    });
            },
        });
    };
    
    

    useEffect(() => {
        if (product) {
            getItemsPerCategoryCount(product.CategoryId, stationId, 6, product.Mkt)
                .then(x => {
                    setShowInList(x.data)
                })
        }
    }, [product])

    useEffect(() => {
        getImgUrl()
    }, [product])

    const changeActiveImg = (imgData) => {
        dispatch(SetImgActive(product.Mkt, imgData.Id, imgData.Name))
    }

    const uploadImg = (data) => {
        let images = imagesList;
        if (replace) {
            const foundIndex = images.indexOf(replace);
            images[foundIndex] = data;
            setReplace(false);

        } else {
            images = images.map(x => ({ ...x, Active: false }));
            images.push({ ...data, Active: true });
        }
        setImagesList(images);
        setPreload(false);
    }

    return (
        product ?

            <Fragment>
                <div className='flex'>
                    <span>הוסף תמונה</span>
                    <MyCropper
                        uploadImg={uploadImg}
                        itemId={product.Mkt}
                        folder="products"
                        NameId="ProductMkt"
                        Mkt={product.Mkt}
                        IdImgGs1={product.IdImgGs1}
                        Table="p_image_url"
                        beforeFileName={`${product.Mkt}_${product.Barcode}_`}
                        element={showInList.find(item => item.Mkt  === product.Mkt)}
                    />
                </div>
                <p>כל התמונות במערכת</p>
                {
                    imagesList.length ?
                        <div className='img-list'>
                            {imagesList.map((x) => (
                                <div key={x.Id} className="img-container">
                                    {!x.Name?.includes('.zip') ? (
                                       <div>
                                           <div className={x.Active ? 'img-active' : 'img'} >
                                                <Img
                                                    src={globalFileServer + "products/" + x.Name}
                                                    onClick={() => changeActiveImg(x)}
                                                    itemId={product.Mkt}
                                                    Mkt={product.Mkt}
                                                    IdImgGs1={product.IdImgGs1}
                                                    beforeFileName={`${product.Mkt}_${product.Barcode}_`}
                                                    uploadImg={uploadImg}
                                                    element={showInList.find(item => item.Mkt  === product.Mkt)}
                                                />
                                                 <img
                                                    className="delete"
                                                    src={globalFileServer + 'iconcss/deleteLocationAndListI.svg'}
                                                    alt="מחק תמונה"
                                                    title='מחיקת תמונה'
                                                    onClick={() => deleteImage({ Mkt: product.Mkt, ItemId: x.Id, FileName: x.Name })}
                                                    />
                                            </div>
                                            <label className="GS1-label">
                                                {x.Name?.includes('Gs1') ? "תמונה של GS1" : ""}
                                            </label>
                                        </div> ) : (
                                        <div>
                                            {/* <ViewThreeSixty
                                                Path={x.Name}
                                                Barcode={product.Barcode}
                                                amount={40}
                                                imagePath={`${globalFileServer}products/gs1/1233_7290000055893_867943_gs1Id_42997`}
                                                fileName="7290000055893_{index}.jpg"
                                            /> */}
                                        </div> )}
                            </div>
                        ))}

                        </div>
                        : <div>אין רשימה להצגה</div>
                }
                <p>הדגמה במערכת  </p>
                {
                    showInList.length > 1 ?
                        // <div className='img-list'>
                        <div className="flex-container">
                            <div className="col-lg-9">
                                {showInList.map((x) => (
                                    <div className='col-lg-2' key={x.Id}>
                                        <div className="item-card role">
                                            <Detailes inCart={false} productPopUp={() => { }} element={x} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        : <div>אין רשימה להצגה</div>
                }
            </Fragment >
            : null
    )
}

export default ProductEditImg

const Img = ({ src, onClick, className, itemId, Mkt, IdImgGs1, beforeFileName, uploadImg, element }) => {
    const [isBig, setIsBig] = useState(false);
    const [srcPrev, setSrcPrev] = useState(src);
    const [cropped, setCropped] = useState(true);

    const dispatch = useDispatch();

    const EditImg = () => {
        setIsBig(!isBig);
        onClick();
    };
    const SetClickImg = () => {
        onClick();
    };
    const saveCroppedImage = (event) => {
        if (event) event.preventDefault();

        if (!cropped) {
            showAlert({
                msgTitle: "שגיאה",
                msgText: "לא גזרת את התמונה.",
                msgType: "error",
            });
            return;
        }

        const fileName =
            new Date()
                .toLocaleTimeString("he-Il", {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                })
                .split(":")
                .join("") + ".jpg";

        dispatch(setPreload(true));

        UploadImageData({
            selectedFile: srcPrev, // שימוש בתמונה הגזורה
            FileName: beforeFileName + fileName,
            ItemId: itemId,
            Mkt: Mkt,
            IdImgGs1: IdImgGs1,
        })
            .then(() =>
                uploadImg({
                    FileName: beforeFileName + fileName,
                    ImgData: srcPrev,
                })
            )
            .catch(() => showAlert())
            .finally(() => setIsBig(false));
    };

    return (
        <Fragment>
            {isBig ? (
                <MyCropperComp
                    src={src}
                    srcPrev={srcPrev}
                    cancel={() => setIsBig(false)}
                    setSrcPrev={setSrcPrev}
                    save={saveCroppedImage}
                    cropped={cropped}
                    setCropped={setCropped}
                    element={element}
                />
            ) : null}
            <img className={className} src={src}  onClick={SetClickImg}/>
             <img
                className="edit"
                src={globalFileServer + 'iconcss/edit.svg'}
                alt="ערוך תמונה"
                title='עריכת תמונה'
                onClick={EditImg}
                /> 
        </Fragment>
    );
};
