import React, { Fragment, useState, useEffect } from 'react'
import { setUserEntry, LogOut, updateStationId } from "../../server/Customer";
import {  globalFileIcon } from '../../../axiosInstance';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import Accessibility from '../Accessibility'
import CustomerService from '../customerService/CustomerService';
import PrivateAreaList from '../privateArea/PrivateAreaList';
import { setToggleCart } from "../../server/CartsOpen";
import { FiArrowLeft } from 'react-icons/fi';
import { GetStation } from "../../server/Station";

const HeaderConnection = ({ user, stationId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState(false);
    const [openPrivateAreaList, setOpenPrivateAreaList] = useState(false);
    const [userStation, setUserStation] = useState(null);
    
    const handleNavigation = () => {
        dispatch(setToggleCart(false));
        navigate('/about');
    };

    const exit = () => {
        navigate('/');
        dispatch(LogOut())
    }

    const check = () => {
        const newStationId = 1;
        dispatch(updateStationId(newStationId));
        user.StationId = newStationId;
    }
    
    useEffect(() => {
        GetStation(user?.StationId).then((x) => setUserStation(x.data));
    }, [user, stationId])
    
    return (
        <div className='header-connection'>
            <NavLink to="/" className='site-icon'><img  loading="lazy" src={globalFileIcon + 'site-icon.svg'} alt="site" /></NavLink>
            <div className='header-data'>

                <li className="data" onClick={handleNavigation}>
                    <img loading="lazy" src={globalFileIcon + 'about.svg'} alt="about" />
                   <span className="about">אודות</span>
                </li>
                <li className={openMenu? "data open-menu":"data" } onClick={() => setOpenMenu(true)} >
                    <img  loading="lazy" src={globalFileIcon + 'customer-service.svg'} alt="customer-service" />
                    <a className='customer-service'>שירות לקוחות</a>
                </li>
                {openMenu ? <div className='customer-service-menu'><CustomerService setOpenMenu={setOpenMenu} /></div> : null}
                <Accessibility />
            </div>
            <div className="login-or-sign-in">
                {!user ?
                    <div>
                        <button className="login" onClick={() => dispatch(setUserEntry([true, "login"]))}>
                            כניסה
                        </button>
                        <button className="sign-in" onClick={() => dispatch(setUserEntry([true, "signIn"]))}>
                            הרשמה
                        </button>
                    </div>
                    :
                    <Fragment>
                        <div>
                            {!user.UserTypeId  ? <Fragment>
                                <button onClick={() => setOpenPrivateAreaList(true)} className="btn-to-private-area">
                                    <span> לאזור האישי של { user.Name } {user.LastName}</span>
                                </button>
                                {openPrivateAreaList ? <div className='private-area-list-menu'><PrivateAreaList setOpenPrivateAreaList={setOpenPrivateAreaList} /></div> : null}
                            </Fragment> : null}

                            <button onClick={exit} className="exit">
                                <span>יציאה</span>
                            </button>
                            <button onClick={()=>navigate(-1)} className="exit">
                                <FiArrowLeft />
                            </button>
                        </div>
                        <div className='station-user'> הי, אנו מגיעים אליך מתחנת {userStation?.Name}
                        </div>
                    </Fragment>
                }

            </div>
        </div>
    )
}

export default HeaderConnection
